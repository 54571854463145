var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"dialog-card-border"},[_c('v-card-title',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Lawyer Contract') : _vm.$t('Add New Lawyer Contract'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{staticClass:"mdil-36px mdil-rotate-45"},[_vm._v(" mdil-plus ")])],1)],1),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Name'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Phone'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Salary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Salary'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.salary),callback:function ($$v) {_vm.$set(_vm.formData, "salary", $$v)},expression:"formData.salary"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Contract Cost'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Contract Cost'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.cost),callback:function ($$v) {_vm.$set(_vm.formData, "cost", $$v)},expression:"formData.cost"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Start Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-date-picker',{attrs:{"label":("* " + (_vm.$t('Start Date'))),"outlined":"","dense":"","no-title":"","error-messages":errors},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('End Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-date-picker',{attrs:{"label":("* " + (_vm.$t('End Date'))),"outlined":"","dense":"","no-title":"","error-messages":errors},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-14",attrs:{"cols":"12","sm":"6"}},[(_vm.addEditDialog)?_c('app-image-uploader',{attrs:{"width":"90%","height":"180px","label":"Image","parent-image-url":_vm.formData.attachment},on:{"image-selected":function($event){_vm.formData.attachment = $event},"is-image-updated":function($event){_vm.formData.attachment_updated = $event}}}):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdil-check ")]),_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Save') : _vm.$t('Add'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }