<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('Name') }}</th>
                    <th>{{ $t('Phone') }}</th>
                    <th>{{ $t('Salary') }}</th>
                    <th>{{ $t('Contract Cost') }}</th>
                    <th>{{ $t('Start Date') }}</th>
                    <th>{{ $t('End Date') }}</th>
                    <th>{{ $t('Image') }}</th>
                    <th
                      style="width: 250px"
                      class="text-center"
                    >
                      <v-btn
                        depressed
                        color="primary"
                        small
                        @click="addNew()"
                      >
                        <v-icon
                          class="mdil-24px"
                          left
                        >
                          mdil-plus
                        </v-icon>
                        {{ $t('Add New') }}
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in results"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.phone }}</td>
                    <td>{{ $_number_format(row.salary) }}</td>
                    <td>{{ $_number_format(row.cost) }}</td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.end_date }}</td>
                    <td class="py-1">
                      <a
                        :href="`${row.attachment}?token=${$_auth_token()}`"
                        target="_blank"
                      >
                        <div style="width: 50px; height: 50px">
                          <img
                            :src="`${row.attachment}?token=${$_auth_token()}`"
                            style="width: 100%; height: 100%; object-fit: cover"
                            class="rounded"
                            alt=""
                          >
                        </div>
                      </a>
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="bg-info-tonal info--text me-3"
                        small
                        depressed
                        @click="update(row)"
                      >
                        <v-icon left>
                          mdi-pencil
                        </v-icon>
                        {{ $t('Edit') }}
                      </v-btn>

                      <!-- <v-btn
                        small
                        depressed
                        disabled
                        @click="view(row)"
                      >
                        <v-icon left>
                          mdi-eye
                        </v-icon>
                        {{ $t('View') }}
                      </v-btn> -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="getData(), checkCompaniesNotification()"
      @update-table-data="getData(), checkCompaniesNotification()"
    />

    <!-- <show-lawyer-contract
      :id="id"
      :show-dialog="showDialog"
      @reset-table-data="getData()"
      @close="showDialog = false"
    /> -->
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'
import { mapActions } from 'vuex'
// import ShowLawyerContract from './ShowLawyerContract.vue'

export default {
  components: {
    AddEdit,
    // ShowLawyerContract,
  },

  data() {
    return {
      results: [],
      formData: {},
      editMode: false,
      company: {},
      id: 0,
      addEditDialog: false,
      showDialog: false,
    }
  },

  mounted() {
    this.getCompanyData().then(() => {
      this.getData()
    })
  },

  methods: {
    ...mapActions([
      'checkCompaniesNotification',
    ]),

    async getCompanyData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.company = res.data
      })
    },

    getData() {
      axios.get('lawyer-contract/', { params: { company_id: this.company.id } }).then(res => {
        this.results = res.data
      })
    },

    addNew() {
      this.formData = {
        company_id: this.company.id,
        name: null,
        phone: null,
        salary: null,
        cost: null,
        attachment: null,
      }
      this.editMode = false
      this.addEditDialog = true
    },

    update(row) {
      this.formData = { ...row }
      this.editMode = true
      this.addEditDialog = true
    },

    view(row) {
      this.id = row.id
      this.showDialog = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
